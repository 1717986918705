.contact-page {
    display: flex;
    flex-direction: column;
    width: 100%;

    background-image: url("../../images/lines.png");
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.contact-page .contact-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.contact-page .contact-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    padding: 72px;
}

.contact-page .contact-body .contact-left {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.contact-page .contact-body .contact-left .questions {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    border-radius: 2px;
    border: 1px solid black;
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}



.contact-page .contact-body .contact-left .questions .question {
    border-bottom: 1px solid black;
    padding: 12px;
    cursor: pointer;
}

.question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.question-header p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.arrow-icon {
    transition: transform 0.3s ease;
}

.rotate {
    transform: rotate(180deg);
}

.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding-top: 10px;
}

.answer.show {
    max-height: 100px;
}



.contact-page .contact-body .contact-left h1 {
    margin-bottom: 18px;
}

.contact-page .contact-body .contact-left .display-card-containers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 18px;
}

.contact-page .contact-body .contact-left .display-card-containers .contact-card-one {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 48%;
    height: 200px;
    background-color: white;
    padding: 18px;


    background: rgba(19, 239, 255, 0.34);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
    border: 1px solid rgba(19, 239, 255, 1);
}

.contact-page .contact-body .contact-left .display-card-containers .contact-card-one h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-page .contact-body .contact-left .display-card-containers .contact-card-one p {
    font-size: 15px;
}

.contact-page .contact-body .contact-left .display-card-containers .contact-card-one .support-icon {
    margin-left: 8px;
}

.contact-page .contact-body .contact-left .display-card-containers .contact-card-two {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 48%;
    min-height: 200px;
    background-color: white;
    padding: 18px;

    background: rgba(255, 115, 19, 0.19);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
    border: 1px solid rgba(255, 115, 19, 1);
}

.contact-page .contact-body .contact-left .display-card-containers .contact-card-two h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-page .contact-body .contact-left .display-card-containers .contact-card-two .support-icon {
    margin-left: 8px;
}

.contact-page .contact-body .contact-left .display-card-containers .contact-card-two p {
    font-size: 15px;
}



.contact-page .contact-body .contact-right {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 18px;
    width: 50%;
    min-height: 70vh;

    border-radius: 2px;
    border: 1px solid black;
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.contact-page .contact-body .contact-right .contact-header {
    font-size: 38px;
    margin-bottom: 12px;
}

.contact-page .contact-body .contact-right .contact-prompt {
    margin-bottom: 22px;
}

.contact-page .contact-body .contact-right .email-box-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-page .contact-body .contact-right .email-box-container p {
    font-size: 15px;
    font-weight: 600;
}

.contact-page .contact-body .contact-right .email-box-container input {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 10px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 10px 1rem;
    transition: 0.25s;
}

.contact-page .contact-body .contact-right .email-box-container input:focus {
    outline-offset: 5px;
    background-color: #fff
}

.contact-page .contact-body .contact-right .message-box-container p {
    font-size: 15px;
    font-weight: 600;
}



.contact-page .contact-body .contact-right .message-box-container textarea {
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
    width: 100%;
    height: 180px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 10px 1rem;
    transition: 0.25s;
}

.contact-page .contact-body .contact-right .message-box-container textarea:focus {
    outline-offset: 5px;
    background-color: #fff
}

.contact-page .contact-body .contact-right .submit-button {
    width: 100%;
    height: 34px;
    margin-top: 22px;
    background-color: rgb(255, 210, 97);
    border: 2px solid black;
    color: red;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.contact-page .contact-body .contact-right .submit-button:hover {
    background-color: rgb(197, 162, 73);
}



@media screen and (max-width: 1000px) {
    .contact-page .contact-body {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .contact-page .contact-body .contact-right {
        width: 80%;
        margin-bottom: 28px;
    }

    .contact-page .contact-body .contact-left {
        width: 80%;
    }
}

@media screen and (max-width: 650px) {
    .contact-page .contact-body .contact-right {
        width: 100%;
        margin-bottom: 28px;
    }

    .contact-page .contact-body .contact-left {
        width: 100%;
    }

    .contact-page .contact-body .contact-left .display-card-containers {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .contact-page .contact-body {
        padding: 40px;
    }
}

@media screen and (max-width: 450px) {
    .contact-page .contact-body {
        padding: 5px;
    }

    .contact-page .contact-body .contact-right {
        padding: 10px;
    }

    .contact-page .contact-body .contact-right .contact-header {
        font-size: 24px;
    }

    .contact-page .contact-body .contact-right .contact-prompt {
        font-size: 16px;
    }
}




