.success-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    background-image: url("../../images/lines.png");
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.success-page .success-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.success-page .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    padding: 52px;
}


.success-page .animation__container {
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.success-page .animation__container h1 {  
    margin-left: 24px;
    font-size: 24px;
}

.success-page .animation__container h1 .highlights {
    color: #1AF067;
}

.success-page .wrapper {
    display:flex;
    justify-content:center;
    align-items:center;
}

.success-page .checkmark__circle { 
    stroke-dasharray: 332;  /* updated */
    stroke-dashoffset: 332;  /* updated */
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #1AF067;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.success-page .checkmark { 
    width: 112px;  /* updated */
    height: 112px;  /* updated */
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #1AF067;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.success-page .checkmark__check { 
    transform-origin: 50% 50%;
    stroke-dasharray: 96;  /* updated */
    stroke-dashoffset: 96;  /* updated */
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke { 
    100% {
        stroke-dashoffset: 0
    }
} 

@keyframes scale { 
    0%, 100% 
    {transform: none}50%{transform: scale3d(1.1, 1.1, 1)}

}

@keyframes fill { 
    100% { 
        box-shadow: inset 0px 0px 0px 60px #1AF067 /* updated */
    }
}

@media screen and (max-width: 680px) {
    .success-page .animation__container {
        height: 85vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .success-page .animation__container h1 { 
        text-align: left;
        margin-top: 44px; 
        margin-left: 0px;
        font-size: 24px;
    }
}