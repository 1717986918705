.warning-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    background-image: url("../../images/lines.png");
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.warning-page .warning-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    padding: 52px;
}

.warning-page .warning-container h1 .attention {
    color: red;
    font-size: 44px;
}


.warning-page .warning-container h1 {
    color: rgb(0,0,80);
    text-align: center;
}

.warning-page .warning-container h1 a {
    color: rgb(42, 39, 214);
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .warning-page .warning-container h1 {
        font-size: 28px;
    }
}

@media screen and (max-width: 600px) {
    .warning-page .warning-container h1 {
        font-size: 22px;
    }
}

@media screen and (max-width: 400px) {
    .warning-page .warning-container {
        padding: 12px;
    }

    .warning-page .warning-container h1 .attention {
        color: red;
        font-size: 24px;
    }

    .warning-page .warning-container h1 {
        font-size: 18px;
    }
}