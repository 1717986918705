.navbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
}

.navbar .bars-icon {
    display: none;
}

.navbar .klc-logo {
    width: 120px;
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.navbar .middle-menu {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    /*width: 30%;*/
    width: 20%;
}

.navbar .middle-menu p {
    font-size: 18px;
    color: red;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.navbar .middle-menu p:hover {
    color: rgb(255, 210, 97);
}

.navbar .login-button {
    width: 120px;
    height: 40px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: red;
    border: 2px solid black;
    border-radius: 2px;
    background-color: rgb(255, 210, 97);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);

    cursor: pointer;
    transition: 0.2s all ease-in;
}

.navbar .login-button:hover {
    background-color: rgb(199, 164, 75);
}

/* Side menu */
.side-menu {
    position: fixed;
    top: 0;
    right: -100%; /* Start off-screen */
    width: 250px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease; /* Smooth sliding */
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 1000;
}

/* Slide the menu into view */
.side-menu.open {
    right: 0;
}

.side-menu p {
    margin: 16px 0;
    cursor: pointer;
}

.close-button {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: red;
    border: 2px solid black;
    border-radius: 2px;
    background-color: rgb(255, 210, 97);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}


@media screen and (max-width: 1000px) {
    .navbar .middle-menu {
        width: 50%;
    }
}

@media screen and (max-width: 750px) {
    .navbar .middle-menu {
        display: none;
    }

    .navbar .login-button {
        display: none;
    }

    .navbar .bars-icon {
        display: flex;
        cursor: pointer;
        transition: transform 0.3s ease; 
    }

    .bars-icon.spin {
        transform: rotate(90deg);
    }
}

@media screen and (max-width: 450px) {
    .navbar .klc-logo {
        width: 80px;
        -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
        box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
    }

    .navbar .bars-icon {
        width: 30px;
    }
}