.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 45px;
    background-color: rgb(0, 0, 60);
    color: white;
    height: 280px;
}

.footer .footer-left {
    display: flex;
    flex-direction: column;
}

.footer .footer-left .footer-logo {
    width: 80px;
}

.footer .footer-left .footer-logo {
    object-fit: contain;
}

.footer .footer-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer .footer-right h3 {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
}

.footer .footer-right .resource-item {
    font-size: 14px;
    font-weight: 200;
    margin-top: 4px;
    color: white;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.footer .footer-right .resource-item:hover {
    color: rgb(226, 226, 226);
}

@media screen and (max-width: 320px) {
    .footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .footer .footer-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}