.register-page {
    display: flex;
    flex-direction: column;
    width: 100%;

    background-image: url("../../images/lines.png");
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.register-page .register-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.register-page .register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 52px;
}

.register-page .register-container .vertical-container {
    width: 50%;
}

.register-page .register-container .vertical-container .register-logo {
    width: 50px;
    margin-bottom: 18px;
}

.register-page .register-container .vertical-container .register-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 44px;
    background-color: rgba(245, 249, 255, 0.562);

    width: 100%;
    min-height: 550px;
    border-radius: 2px;
    border: 1px solid rgb(87, 118, 255);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.register-page .register-container .vertical-container .options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
}

.register-page .register-container .vertical-container .options-container .yesaccount {
    font-size: 14px;
}

.register-page .register-container .vertical-container .options-container .yesaccount .loginnow {
    color: blue;
    cursor: pointer;
}

.register-page .register-container .vertical-container .options-container .options-container-list {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: blue;
    list-style: none;
}

.register-page .register-container .vertical-container .options-container .options-container-list li {
    margin-right: 10px;
    cursor: pointer;
}

.register-page .register-container .vertical-container .register-form {
    cursor: pointer;
}

.register-page .register-container .vertical-container .register-form .page-one {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.register-page .register-container .vertical-container .register-form .page-one select {
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
    height: 34px;
    font-size: 18px;
}

.register-page .register-container .vertical-container .register-form .page-one .time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 260px;
    margin-top: 12px;
    overflow-y: scroll;
}


.register-page .register-container .vertical-container .register-form .page-one .time-container .time-slot {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  touch-action: manipulation;
  display: inline-block;
  outline: none;
  font-size: 17px;
  font-weight: 500;
  box-sizing: border-box;
  border: none;
  border-radius: 0.3em;
  height: 2.75em;
  line-height: 2.5em;
  text-transform: uppercase;
  padding: 0 1em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
  inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
  inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
  background-image: linear-gradient(
    160deg,
    #a54e07,
    #b47e11,
    #fef1a2,
    #bc881b,
    #a54e07
  );
  border: 1px solid #a55d07;
  color: rgb(0, 0, 0);
  text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-size: 100% 100%;
  background-position: center;
}
  
.register-page .register-container .vertical-container .register-form .page-one .time-container .time-slot:focus,
.register-page .register-container .vertical-container .register-form .page-one .time-container .time-slot:hover {
  background-size: 150% 150%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23),
  inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
  border: 1px solid rgba(165, 93, 7, 0.6);
  color: rgba(120, 50, 5, 0.8);
}

.register-page .register-container .vertical-container .register-form .page-one .time-container .time-slot:has(input[type="radio"]:checked) {
  background-size: 150% 150%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(150, 150, 150, 0.4), /* Changed to grey tones */
  inset 0 -2px 5px 1px rgba(128, 128, 128, 1), /* Darker silver for depth */
  inset 0 -1px 1px 3px rgba(200, 200, 200, 1); /* Lighter silver for highlights */
  background-image: linear-gradient(
    160deg,
    #7a7a7a, /* Darker silver at the edges */
    #a0a0a0, /* Medium silver transitioning in */
    #e0e0e0, /* Lighter silver, approaching the center */
    rgb(248, 248, 248), /* Brightest point for shine in the middle */
    #e0e0e0, /* Light silver after the middle */
    #a0a0a0, /* Medium silver transitioning out */
    #7a7a7a  /* Darker silver at the edges */
  );
  border: 1px solid #a0a0a0;
  color: rgb(0, 0, 0);
  text-shadow: 0 2px 2px rgba(180, 180, 180, 1);
  
}
  
.register-page .register-container .vertical-container .register-form .page-one .time-container .time-slot:active {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
  inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
}
  

.register-page .register-container .vertical-container .register-form .page-one .time-container .time-slot input[type="radio"] {
    appearance: none;
}

.register-page .register-container .vertical-container .register-form .page-one .time-container .unavailable-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px dashed black;
}

.register-page .register-container .vertical-container .register-form .page-one .time-container .unavailable-container h3 {
    font-size: 20px;
    font-weight: 400;
    text-decoration: underline;
    text-align: center;
}

.register-page .register-container .vertical-container .register-form .page-one .nextButton {
    margin-top: 40px;
    width: 100%;
    height: 40px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: red;
    border: 2px solid black;
    border-radius: 2px;
    background-color: rgb(255, 210, 97);
    cursor: pointer;
    transition: 0.2s all ease-in;

    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.register-page .register-container .vertical-container .register-form .page-one .nextButton:hover {
    background-color: rgb(199, 164, 75);
}

.register-page .register-container .vertical-container .register-form .page-two {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.register-page .register-container .vertical-container .register-form .page-two .page-two-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.register-page .register-container .vertical-container .register-form .page-two .info-form {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 350px;
    width: 100%;
    padding: 10px;
}

.register-page .register-container .vertical-container .register-form .page-two .info-form h3 {
    margin-bottom: 12px;
    font-weight: 500;
    border-bottom: 0.5px solid rgb(87, 118, 255);
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .input-container {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .input-container input {
    width: 48%;
    font-size: 17px;

    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 10px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 5px .5rem;
    transition: 0.25s;
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .input-container input:focus {
    outline-offset: 5px;
    background-color: #fff
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .birthday-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .birthday-container input {
    width: 100%;
    font-size: 15px;

    border-radius: 10px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 5px .5rem;
    transition: 0.25s;
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .birthday-container input:focus {
    outline-offset: 5px;
    background-color: #fff
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .agreements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .agreements .checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.register-page .register-container .vertical-container .register-form .page-two .info-form .agreements .checkboxContainer label {
    font-size: 14px;
    margin-left: 8px;
}

.register-page .register-container .vertical-container .register-form .page-two .nextButton {
    margin-top: 40px;
    width: 100%;
    height: 40px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: red;
    border: 2px solid black;
    border-radius: 2px;
    background-color: rgb(255, 210, 97);
    cursor: pointer;
    transition: 0.2s all ease-in;

    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.register-page .register-container .vertical-container .register-form .page-two .nextButton:hover {
    background-color: rgb(199, 164, 75);
}

.register-page .register-container .vertical-container .register-form .page-two .info-form h3 {
    margin-top: 14px;
}

.register-page .register-container .vertical-container .register-form .page-three {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.register-page .register-container .vertical-container .register-form .page-three .page-three-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.register-page .register-container .vertical-container .register-form .page-three .page-three-top h2 {
    margin-bottom: 28px;
}


@media screen and (max-width: 1000px) {
    .register-page .register-container .vertical-container {
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .register-page .register-container .vertical-container {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .register-page .register-container {
        padding: 20px;
    }

    .register-page .register-container .vertical-container .register-form .page-two .info-form .input-container {
        margin-bottom: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .register-page .register-container .vertical-container .register-form .page-two .info-form .input-container input {
        width: 100%;
        font-size: 17px;
    
        margin-top: 4px;
        margin-bottom: 14px;
        border-radius: 10px;
        outline: 2px solid rgb(255, 210, 97);
        border: 0;
        background-color: #ebebeb;
        outline-offset: 3px;
        padding: 5px .5rem;
        transition: 0.25s;
    }

    .register-page .register-container .vertical-container .register-form {
        padding: 20px;
    }

    .register-page .register-container .vertical-container .register-form .page-one h2 {
        font-size: 20px;
        margin-bottom: 18px;
    }

    .register-page .register-container .vertical-container .register-form .page-two .page-two-top .top-left h2 {
        font-size: 20px;
        margin-bottom: 18px;
    }
}

@media screen and (max-width: 400px) {
    .register-page .register-container .vertical-container .register-logo {
        display: none;
    }

    .register-page .register-container {
        padding: 0px;
        margin-top: 28px;
    }

    .register-page .register-container .vertical-container {
        padding: 0px;
    }

    .register-page .register-container .vertical-container .register-form {
        padding: 10px;
    }
}