.login-page {
    display: flex;
    flex-direction: column;
    width: 100%;

    background-image: url("../../images/lines.png");
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.login-page .login-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.login-page .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 82px;
}

.login-page .login-container .vertical-container {
    width: 40%;


}

.login-page .login-container .vertical-container .login-logo {
    width: 50px;
    margin-bottom: 18px;
}

.login-page .login-container .vertical-container .login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 44px;
    background-color: rgba(245, 249, 255, 0.562);

    width: 100%;
    height: 450px;
    border-radius: 2px;
    border: 1px solid rgb(87, 118, 255);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.login-page .login-container .vertical-container .login-form .login-email-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
}

.login-page .login-container .vertical-container .login-form .login-email-container input {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 10px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 10px 1rem;
    transition: 0.25s;
}  

.login-page .login-container .vertical-container .login-form .login-email-container input:focus {
    outline-offset: 5px;
    background-color: #fff
}

.login-page .login-container .vertical-container .login-form .login-email-container p {
    font-size: 15px;
    font-weight: 600;
}

.login-page .login-container .vertical-container .login-form .login-password-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
}

.login-page .login-container .vertical-container .login-form .login-password-container input {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 10px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 10px 1rem;
    transition: 0.25s;
}  

.login-page .login-container .vertical-container .login-form .login-password-container input:focus {
    outline-offset: 5px;
    background-color: #fff
}

.login-page .login-container .vertical-container .login-form .login-password-container p {
    font-size: 15px;
    font-weight: 600;
}

.login-page .login-container .vertical-container .login-form button {
    margin-top: 80px;
    width: 100%;
    height: 40px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: red;
    border: 2px solid black;
    border-radius: 2px;
    background-color: rgb(255, 210, 97);
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.login-page .login-container .vertical-container .login-form button:hover {
    background-color: rgb(199, 164, 75);
}

.login-page .login-container .vertical-container .options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
}

.login-page .login-container .vertical-container .options-container .noaccount {
    font-size: 14px;
}

.login-page .login-container .vertical-container .options-container .noaccount .startnow {
    color: blue;
    cursor: pointer;
}

.login-page .login-container .vertical-container .options-container .options-container-list {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: blue;
    list-style: none;
}

.login-page .login-container .vertical-container .options-container .options-container-list li {
    margin-right: 10px;
    cursor: pointer;
}


@media screen and (max-width: 1090px) {
    .login-page .login-container .vertical-container {
        width: 80%;
    }
}

@media screen and (max-width: 620px) {
    .login-page .login-container .vertical-container {
        width: 90%;
    }
}

@media screen and (max-width: 580px) {
    .login-page .login-container .vertical-container {
        width: 100%;
    }

    .login-page .login-container .vertical-container .login-form h2 {
        font-size: 24px;
    }
}

@media screen and (max-width: 500px) {
    .login-page .login-container {
        padding: 32px;
    }

    .login-page .login-container .vertical-container {
        width: 100%;
    }

    .login-page .login-container .vertical-container .login-form h2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 400px) {
    .login-page .login-container {
        padding: 24px;
    }

    .login-page .login-container .vertical-container {
        width: 100%;
    }

    .login-page .login-container .vertical-container .login-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        background-color: rgba(245, 249, 255, 0.562);
    
        width: 100%;
        height: 380px;
    }

    .login-page .login-container .vertical-container .login-form h2 {
        font-size: 20px;
    }

    .login-page .login-container .vertical-container .login-form .login-email-container {
        margin-top: 10px;
    }

    .login-page .login-container .vertical-container .login-form .login-password-container {
        margin-top: 10px;
    }

    .login-page .login-container .vertical-container .login-form button {
        margin-top: 50px;
    }

    .login-page .login-container .vertical-container .options-container .options-container-list {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: blue;
        list-style: none;
    }
}