.forgot-password-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: url("../../images/lines.png");
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.forgot-password-page .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.forgot-password-page .forgot-password-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 82px;
    height: 90vh;
}

.forgot-password-page .forgot-password-wrapper .forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 44px;
    background-color: rgba(255, 255, 228, 0.65);

    width: 50%;
    height: 450px;
    border-radius: 2px;
    border: 1px solid rgb(255, 210, 97);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.forgot-password-page .forgot-password-wrapper .forgot-password-form h1 {
    font-size: 34px;
    margin-bottom: 18px;
}

.forgot-password-page .forgot-password-wrapper .forgot-password-form input {
    margin-top: 34px;
    width: 60%;
    border-radius: 10px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 10px 1rem;
    transition: 0.25s;
}

.forgot-password-page .forgot-password-wrapper .forgot-password-form input:focus {
    outline-offset: 5px;
    background-color: #fff
}


.forgot-password-page .forgot-password-wrapper .forgot-password-form .bolt-button {
    --bezier: cubic-bezier(0.22, 0.61, 0.36, 1);
    --edge-light: hsla(0, 0%, 50%, 0.8);
    --text-light: rgba(255, 255, 255, 0.4);
    --back-color: 240, 100%;
    margin-top: 34px;

    cursor: pointer;
    padding: 0.7em 1em;
    border-radius: 0.5em;
    min-height: 2.4em;
    min-width: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    gap: 0.5em;

    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 1;
    font-weight: bold;

    background: linear-gradient(
        140deg,
        hsla(var(--back-color), 50%, 1) min(2em, 20%),
        hsla(var(--back-color), 50%, 1) min(8em, 100%)
    );
    color: hsla(0, 0%, 90%);
    border: 0;
    box-shadow: inset 0.4px 1px 4px var(--edge-light);

    transition: all 0.1s var(--bezier);
}

.forgot-password-page .forgot-password-wrapper .forgot-password-form .bolt-button:hover {
    --edge-light: hsla(0, 0%, 50%, 1);
    text-shadow: 0px 0px 10px var(--text-light);
    box-shadow: inset 0.4px 1px 4px var(--edge-light),
        2px 4px 8px hsla(0, 0%, 0%, 0.295);
    transform: scale(1.1);
}

.forgot-password-page .forgot-password-wrapper .forgot-password-form .bolt-button:active {
    --text-light: rgba(255, 255, 255, 1);

    background: linear-gradient(
        140deg,
        hsla(var(--back-color), 50%, 1) min(2em, 20%),
        hsla(var(--back-color), 50%, 0.6) min(8em, 100%)
    );
    box-shadow: inset 0.4px 1px 8px var(--edge-light),
        0px 0px 8px hsla(var(--back-color), 50%, 0.6);
    text-shadow: 0px 0px 20px var(--text-light);
    color: hsla(0, 0%, 100%, 1);
    letter-spacing: 0.1em;
    transform: scale(1);
}


.forgot-password-page .forgot-password-wrapper .forgot-password-form .back-to-login {
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .forgot-password-page .forgot-password-wrapper .forgot-password-form {
        width: 80%;
    }
    
    .forgot-password-page .forgot-password-wrapper .forgot-password-form h1 {
        font-size: 28px;
    }
}

@media screen and (max-width: 700px) {
    .forgot-password-page .forgot-password-wrapper .forgot-password-form {
        width: 100%;
    }
    
    .forgot-password-page .forgot-password-wrapper .forgot-password-form h1 {
        font-size: 28px;
    }

    .forgot-password-page .forgot-password-wrapper .forgot-password-form input {
        margin-top: 34px;
        width: 100%;
        border-radius: 10px;
        outline: 2px solid rgb(255, 210, 97);
        border: 0;
        background-color: #ebebeb;
        outline-offset: 3px;
        padding: 10px 1rem;
        transition: 0.25s;
    }

    .forgot-password-page .forgot-password-wrapper .forgot-password-form .bolt-button {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .forgot-password-page .forgot-password-wrapper {
        padding: 32px;
    }

    .forgot-password-page .forgot-password-wrapper .forgot-password-form {
        width: 100%;
        padding: 22px;
    }

    .forgot-password-page .forgot-password-wrapper .forgot-password-form h1 {
        font-size: 22px;
        font-weight: 400;
    }
}

@media screen and (max-width: 700px) {
    .forgot-password-page .forgot-password-wrapper {
        padding: 0px;
        margin-top: 28px;
    }

    .forgot-password-page .forgot-password-wrapper .forgot-password-form h1 {
        font-size: 20px;
        font-weight: 400;
    }
}