.wonderful {
    width: 100%;
    padding: 0px;
    border-radius: 0px;
}

.wonderful .outer {
  width: 100%;
  height: 480px;
  border-radius: 0px;
  padding: 0px;
  background: radial-gradient(circle 230px at 0% 0%, #ffffff, rgb(0, 0, 60));
  position: relative;
}

.wonderful .dot {
  width: 5px;
  aspect-ratio: 1;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 10px #ffffff;
  border-radius: 100px;
  z-index: 2;
  right: 10%;
  top: 10%;
  animation: moveDot 6s linear infinite;
}

@keyframes moveDot {
  0%,
  100% {
    top: 10%;
    right: 10%;
  }
  25% {
    top: 10%;
    right: calc(100% - 10%);
  }
  50% {
    top: calc(100% - 10%);
    right: calc(100% - 10%);
  }
  75% {
    top: calc(100% - 10%);
    right: 10%;
  }
}

.wonderful .card {
  z-index: 1;
  width: 100%;
  height: 100%;
  border: solid 1px rgb(0, 0, 60);
  background-size: 20px 20px;
  background: radial-gradient(circle 280px at 0% 0%, #444444, rgb(0, 0, 60));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  color: #fff;
}

.wonderful .ray {
  width: 220px;
  height: 45px;
  border-radius: 100px;
  position: absolute;
  background-color: #c7c7c7;
  opacity: 0.4;
  box-shadow: 0 0 50px #fff;
  filter: blur(10px);
  transform-origin: 10%;
  top: 0%;
  left: 0;
  transform: rotate(40deg);
}

.wonderful .card .text {
  font-weight: bolder;
  font-size: 4rem;
  background: linear-gradient(45deg, gold 4%, #fff, gold);
  background-clip: text;
  color: transparent;
}

.wonderful .line {
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: goldenrod;
}

.wonderful .topl {
  top: 10%;
  background: linear-gradient(90deg, #dfdfdf 30%, goldenrod 70%);
}

.wonderful .bottoml {
  bottom: 10%;
  background: linear-gradient(90deg, goldenrod 30%, #dfdfdf 70%);
}

.wonderful .leftl {
  left: 10%;
  width: 1px;
  height: 100%;
  background: linear-gradient(90deg, #dfdfdf 30%, goldenrod 70%);
}

.wonderful .rightl {
  right: 10%;
  width: 1px;
  height: 100%;
  background: linear-gradient(90deg, #dfdfdf 30%, goldenrod 70%);
}
