.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.loading-text {
  color: rgb(0,0,80);
  font-size: 28px;
  font-weight: 600;
  margin-left: 10px;
}

.dot {
  margin-left: 3px;
  animation: blink 1.5s infinite;
}
.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.loading-bar-background {
  --height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  width: 300px;
  height: var(--height);
  background-color: rgb(0,0,80);
  box-shadow: rgb(0,0,80) -2px 2px 4px 0px inset;
  border-radius: calc(var(--height) / 2);
}

.loading-bar {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  --height: 30px;
  width: 0%;
  height: var(--height);
  overflow: hidden;
  background: rgb(222, 74, 15);
  background: linear-gradient(
    0deg,
    rgba(222, 74, 15, 1) 0%,
    rgba(249, 199, 79, 1) 100%
  );
  border-radius: calc(var(--height) / 2);
  animation: loading 4s ease-out infinite;
}

.white-bars-container {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 18px;
}

.white-bar {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  width: 10px;
  height: 45px;
  opacity: 0.3;
  rotate: 45deg;
}

@keyframes loading {
  0% {
    width: 0;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
