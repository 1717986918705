.pay {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pay .paymentElement {
    width: 100%;
}

.pay .stripeSubmitButton {
    margin-top: 44px;
    width: 100%;
    height: 40px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: red;
    border: 2px solid black;
    border-radius: 2px;
    background-color: rgb(255, 210, 97);
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.pay .stripeSubmitButton:hover {
    font-weight: 600;
    color: rgb(255, 210, 97);
    border: 2px solid black;
    border-radius: 2px;
    background-color: red;
}

.pay-load {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}