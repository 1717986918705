.rr__container {
    padding: 72px;
}

.rr__container h3 {
    margin-top: 22px;
    margin-bottom: 22px;
}

.rr__container h1 {
    margin-top: 22px;
    margin-bottom: 22px;
}

.rr__container .toc {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(204, 204, 204);
    padding: 24px;
    width: 100%;
    margin-bottom: 28px;
}

.rr__container .toc ol {
    margin-left: 28px;
    margin-bottom: 22px;
}

.rr__container .toc ol li {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 13px;
}

@media screen and (max-width: 400px) {
    .rr__container {
        padding: 32px;
    }

    .rr__container p {
        font-size: 14px;
    }
}