.two {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 120px;
    background-color: transparent;
    width: 100%;

    background-image: url("../../images/lines.png");
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.two .tagline {
    width: 70%;
    color: rgb(0, 0, 60);
    text-align: left;
    font-size: 48px;
    text-shadow: 2px 2px 15px rgba(0,0,0,0.1);

    margin-bottom: 44px;
}

.two .video-games {
    background: #940DFF;
    background: radial-gradient(ellipse farthest-side at left center, #940DFF 50%, #FF0763 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.two .apps {
    background: #FF0785;
    background: radial-gradient(ellipse farthest-side at left center, #FF0785 23%, #940DFF 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.two .animButton {
    width: 30%;
  --black-700: #940DFF; /* Updated to purple */
  --border_radius: 8px;
  --transtion: 0.3s ease-in-out;
  --offset: 2px;

  cursor: pointer;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  transform-origin: center;

  padding: 1rem 2rem;
  background-color: transparent;

  border: none;
  border-radius: var(--border_radius);
  transform: scale(calc(1 + (var(--active, 0) * 0.1)));

  transition: transform var(--transtion);
}

.two .animButton::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  width: 100%;
  height: 100%;
  background-color: var(--black-700); /* Updated to purple background */
  
  border-radius: var(--border_radius);
  box-shadow: inset 0 0.5px hsl(0, 0%, 100%), inset 0 -1px 2px 0 #940DFF, /* Updated shadow color */
    0px 4px 10px -4px hsla(0 0% 0% / calc(1 - var(--active, 0))),
    0 0 0 calc(var(--active, 0) * 0.375rem) hsla(270, 97%, 50%, 0.75); /* Adjusted to purple tones */

  transition: all var(--transtion);
  z-index: 0;
}

.two .animButton::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  width: 100%;
  height: 100%;
  
  /* Modify the hover gradient to match the purple background color */
  background-color: hsla(270, 97%, 50%, 0.75); /* Updated to purple tone */
  background-image: radial-gradient(
      at 51% 89%,
      hsla(270, 45%, 60%, 1) 0px, /* Purple gradient */
      transparent 50%
    ),
    radial-gradient(at 100% 100%, hsla(270, 36%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 22% 91%, hsla(270, 36%, 50%, 1) 0px, transparent 50%);
  background-position: top;

  opacity: var(--active, 0);
  border-radius: var(--border_radius);
  transition: opacity var(--transtion);
  z-index: 2;
}

.two .animButton:is(:hover, :focus-visible) {
  --active: 1;
  background-color: #940DFF; /* Updated hover background to purple */
  
  /* Ensure the border expands into purple, matching the button's background */
  box-shadow: 0 0 0 calc(var(--active, 0) * 0.375rem) hsla(270, 97%, 50%, 0.75); /* Updated to purple */
}

.two .animButton:active {
  transform: scale(1);
}

.two .animButton .dots_border {
  --size_border: calc(100% + 2px);

  overflow: hidden;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: var(--size_border);
  height: var(--size_border);
  background-color: transparent;

  border-radius: var(--border_radius);
  z-index: -10;
}

.two .animButton .dots_border::before {
  content: "";
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left;
  transform: rotate(0deg);

  width: 100%;
  height: 2rem;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 150, 0.8) 0%, /* Pinkish color */
    rgba(0, 128, 255, 0.8) 50%, /* Blue color */
    rgba(0, 255, 128, 0.8) 100% /* Greenish color */
  ); 
  box-shadow: 0 0 10px rgba(255, 0, 150, 0.5), 
  0 0 20px rgba(0, 128, 255, 0.5), 
  0 0 30px rgba(0, 255, 128, 0.5);
  mask: linear-gradient(transparent 0%, white 120%);
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.two .animButton .sparkle {
  position: relative;
  z-index: 10;

  width: 1.75rem;
}

.two .animButton .sparkle .path {
  fill: currentColor;
  stroke: currentColor;

  transform-origin: center;

  color: hsl(0, 0%, 100%);
}

.two .animButton:is(:hover, :focus) .sparkle .path {
  animation: path 1.5s linear 0.5s infinite;
}

.two .animButton .sparkle .path:nth-child(1) {
  --scale_path_1: 1.2;
}

.two .animButton .sparkle .path:nth-child(2) {
  --scale_path_2: 1.2;
}

.two .animButton .sparkle .path:nth-child(3) {
  --scale_path_3: 1.2;
}

@keyframes path {
  0%,
  34%,
  71%,
  100% {
    transform: scale(1);
  }
  17% {
    transform: scale(var(--scale_path_1, 1));
  }
  49% {
    transform: scale(var(--scale_path_2, 1));
  }
  83% {
    transform: scale(var(--scale_path_3, 1));
  }
}

.two .animButton .text_button {
  position: relative;
  z-index: 10;

  background-image: linear-gradient(
    90deg,
    hsla(0 0% 100% / 1) 100%,
    hsla(0 0% 100% / var(--active, 0)) 120%
  );
  
  background-clip: text;

  font-size: 1rem;
  font-weight: 700;
  color: transparent;
}



@media screen and (max-width: 1000px) {
  .two {
    width: 100%;
  }

  .two .tagline {
    width: 100%;
  }

  .two .animButton {
      width: 60%;
  }
}

@media screen and (max-width: 720px) {
  .two {
    width: 100%;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 72px;
  }

  .two .tagline {
    width: 100%;
  }

  .two .animButton {
      width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .two {
    width: 100%;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 72px;
  }

  .two .tagline {
    width: 100%;
    font-size: 38px;
    text-align: center;
  }

  .two .animButton {
      width: 100%;
  }
}


@media screen and (max-width: 550px) {
  .two {
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 72px;
  }

  .two .tagline {
    width: 100%;
    font-size: 34px;
    text-align: center;
  }
}