.hero-banner {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;


    background-image: url("../../images/lines.png");
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    /*background-image: url("../images/cover2.png");
    background-size: contain;
    
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;*/
}

.hero-banner .hero-image {
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    z-index: -1;
}

.hero-banner .strawberryGuy {
    position: absolute;
    left: 20px;
    top: 44%;
    width: 300px;
    animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);  
    }
    50% {
        transform: translateY(-20px); 
    }
}

.hero-banner .professorCookie {
    position: absolute;
    right: 8%;
    top: 47%;
    width: 230px;
    transition: transform 1.5s ease-in-out;
}

@keyframes tilt {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* Trigger the animation on hover */
.hero-banner .professorCookie:hover {
    animation: tilt 2s ease-in-out;
}


.hero-banner .water-pipe {
    position: absolute;
    top: 80vh;
    width: 20%;
    object-fit: contain;
    left: 0;
    z-index: -1;
}

.hero-banner .tagline {
    text-align: center;
    margin-top: 120px;
    font-size: 74px;
    color: rgb(0, 0, 60);
    text-shadow: 2px 2px 15px rgba(0,0,0,0.3);
}
.hero-banner .tagline .software-engineers {
    color: rgb(105, 45, 235);
}



@media screen and (max-width: 880px) {
    .hero-banner .tagline {
        text-align: center;
        margin-top: 120px;
        font-size: 58px;
        color: rgb(0, 0, 60);
        text-shadow: 2px 2px 15px rgba(0,0,0,0.3);
    }
}

@media screen and (max-width: 680px) {
    .hero-banner {
        min-height: 80vh;
    }
    .hero-banner .tagline {
        text-align: center;
        margin-top: 70px;
        font-size: 54px;
        color: rgb(0, 0, 60);
        text-shadow: 2px 2px 15px rgba(0,0,0,0.3);
    }
}

@media screen and (max-width: 536px) {
    .hero-banner {
        min-height: 80vh;
    }
    .hero-banner .tagline {
        text-align: center;
        width: 80%;
        margin-top: 70px;
        font-size: 38px;
        color: rgb(0, 0, 60);
        text-shadow: 2px 2px 15px rgba(0,0,0,0.3);
    }
}


