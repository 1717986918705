.termOfService__container {
    padding: 72px;
}

.termOfService__container h3 {
    margin-bottom: 12px;
}

.termOfService__container h1 {
    margin-top: 22px;
    margin-bottom: 22px;
}

.termOfService__container .link {
    color: blue;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.termOfService__container ul {
    margin-left: 28px;
    margin-top: 22px;
    margin-bottom: 22px;
}

.termOfService__container ul li {
    margin-top: 8px;
    margin-bottom: 8px;
}

.termOfService__container .toc {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(204, 204, 204);
    padding: 24px;
    width: 100%;
}

.termOfService__container .toc ol {
    margin-left: 28px;
    margin-top: 22px;
    margin-bottom: 22px;
}

.termOfService__container .toc ol li {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 13px;
}

@media screen and (max-width: 400px) {
    .termOfService__container {
        padding: 32px;
    }

    .termOfService__container p {
        font-size: 14px;
    }
}